import React, { ReactNode } from 'react'
import { Modal as BModal } from 'react-bootstrap'

interface ModalProps {
  header: ReactNode
  footer?: ReactNode
  isOpen: boolean
  handleClose(): void
}

export const Modal: React.FC<ModalProps> = ({ header, footer, handleClose, isOpen, children }) => {
  return (
    <BModal show={isOpen} onHide={handleClose}>
      <BModal.Dialog className="m-0">
        <BModal.Header closeButton>{header}</BModal.Header>

        <BModal.Body>{children}</BModal.Body>

        {footer ? <BModal.Footer>{footer}</BModal.Footer> : null}
      </BModal.Dialog>
    </BModal>
  )
}
