import { put, call, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { FileProps } from 'types/file'
import { AlertVariant } from 'types/alert'
import { AttachPdfData } from 'types/data-gateway'
import { OrderFields } from 'types/order'
import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { selectOrderRead } from 'store/order/order.selectors'
import { setInitialValuesOrderRead } from 'store/order/order.actions'

import { attachPDFToOrder } from 'requests/data-gateway'

import { attachPdfSuccess, attachPdfFailure } from '../data-gateway.actions'

export function* attachPdfToOrderSaga(action: Action<AttachPdfData>) {
  try {
    const token = yield select(selectToken)

    const { orderNumber, query } = action.payload

    const file: FileProps = yield call(attachPDFToOrder, orderNumber, query, token)

    yield put(attachPdfSuccess())
    const ordersRead = yield select(selectOrderRead)
    const files = ordersRead[OrderFields.FILES]
    yield put(
      setInitialValuesOrderRead({
        [OrderFields.FILES]: [...files, file],
      }),
    )
    yield put(
      addAlert({
        text: `Pdf по водителю успешно загружен.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Прикрепление pdf к заявке',
      }),
    )
  } catch (error) {
    yield put(attachPdfFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Прикрепление pdf к заявке',
        defaultText: 'Не удалось прикрепить PDF к заявке.',
      }),
    )
    console.error(`[fetchGetIdDataGateway]: ${error}`)
  }
}
